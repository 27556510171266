/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "./assets/base.scss";
@import "../node_modules/primeicons/primeicons.css";

html {
  background-color: $background-color;
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  background-color: $background-color;
}

.transparent-button-light {
  color: $primary-text-color;
  background: none;
  box-shadow: none;
  border: none;
  padding: 0;
  width: 10px;
}

.transparent-button-light-big {
  color: $primary-text-color;
  background: none;
  box-shadow: none;
  border: none;
  padding: 0px;
}

.material-icons {
  font-family: "Material Icons" !important;
}

.confirm-button {
  background-color: $primary-button-color;
  color: $secondary-text-color;
  height: 2.6em;
  font-size: 0.85rem;
  font-weight: 400;
  transition: background-color 0.257s ease;
  padding-left: 1.5em;
  padding-right: 1.5em;
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: none;
  display: inline-flex;
  line-height: 1.5;
  padding-bottom: calc(0.375em - 1px);
  padding-top: calc(0.375em - 1px);
  position: relative;
  vertical-align: top;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
}

.confirm-button:disabled {
  background-color: $primary-button-disabled;
  cursor: not-allowed;
}

.rounded-button {
  top: -4px;
  padding-left: 2em;
  padding-right: 2em;
  border-radius: 290486px;
  height: 2.6em;
  font-weight: 400;
  transition: background-color 0.257s ease;
  background-color: $light-background-color;
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid $border-color;
  padding-top: calc(0.375em - 1px);
  position: relative;
  vertical-align: top;
}

.clear-button {
  background-color: $secondary-button-color;
  color: $primary-text-color;
  height: 2.6em;
  font-size: 0.85rem;
  font-weight: 400;
  transition: background-color 0.257s ease;
  padding-left: 1.5em;
  padding-right: 1.5em;
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid $border-color;
  border-radius: 3px;
  box-shadow: none;
  display: inline-flex;
  line-height: 1.5;
  padding-bottom: calc(0.375em - 1px);
  padding-top: calc(0.375em - 1px);
  position: relative;
  vertical-align: top;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
}

.clear-button:disabled {
  color: $primary-text-disabled;
}

/* To display a border for the input */
::ng-deep.mat-input-flex.mat-form-field-flex {
  border: 1px solid black;
}

/* To remove the default underline */
::ng-deep.mat-input-underline.mat-form-field-underline {
  background: transparent;
}

/* To remove the underline ripple */
::ng-deep.mat-input-ripple.mat-form-field-ripple {
  background-color: transparent;
}

hr {
  border-top: 1px solid $seperator-line-color;
}

.checkbox-group {
  vertical-align: middle;
  display: inline-block;
  margin-top: 6px;
}

.checkbox {
  vertical-align: middle;
  margin-right: 1.5vw;
}

.input-container {
  position: relative;
  margin-bottom: 1rem;
}
.input {
  margin: 0;
  padding: 0;
  padding-right: 30px;
  padding-left: 10px;
  width: 100%;
}
.input-icon {
  position: absolute;
  bottom: -1px;
  right: 5px;
  padding: 0;
}

.expanding-textarea {
  white-space: pre-line;
  width: 100%;
  min-height: 38px;
  display: block;
  padding: 0.375rem 0.75rem;
  field-sizing: content;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid $border-color !important;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.expanding-textarea.disabled {
  height: unset;
}

.expanding-textarea:hover,
mat-select:hover {
  border: 1px solid #212121 !important;
  box-shadow: unset !important;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.expanding-textarea:focus,
mat-select:focus {
  border: 1px solid #007ad9 !important;
  box-shadow: unset !important;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:disabled {
  background: white;
}

body,
.form-control {
  font-size: 13px;
}

.ui-datepicker {
  padding: 5px !important;
}

.ui-datepicker-trigger.ui-button {
  background: grey;
  border: 1px solid grey;
}

.ui-inputtext {
  height: 100%;
}
.form-control {
  border: 1px solid #a6a6a6;
}

body .ui-datepicker table td {
  padding: unset !important;
}

body .ui-datepicker .ui-timepicker {
  padding: unset !important;
  font-size: 10px;
}

.button-checkbox {
  vertical-align: middle;
}

body .ui-state-disabled,
body .ui-widget:disabled {
  opacity: 1;
}

.form-control,
.expanding-textarea {
  min-height: 33.56px;
}

.mat-select-trigger {
  vertical-align: text-top;
}

.mat-select-disabled .mat-select-value {
  color: unset;
}

body .ui-inputtext.ng-dirty.ng-invalid,
body p-dropdown.ng-dirty.ng-invalid > .ui-dropdown,
body p-autocomplete.ng-dirty.ng-invalid > .ui-autocomplete > .ui-inputtext,
body p-datepicker.ng-dirty.ng-invalid > .ui-datepicker > .ui-inputtext,
body p-chips.ng-dirty.ng-invalid > .ui-inputtext,
body p-inputmask.ng-dirty.ng-invalid > .ui-inputtext,
body p-checkbox.ng-dirty.ng-invalid .ui-chkbox-box,
body p-radiobutton.ng-dirty.ng-invalid .ui-radiobutton-box,
body p-inputswitch.ng-dirty.ng-invalid .ui-inputswitch,
body p-listbox.ng-dirty.ng-invalid .ui-inputtext,
body p-multiselect.ng-dirty.ng-invalid > .ui-multiselect,
body p-spinner.ng-dirty.ng-invalid > .ui-inputtext,
body p-selectbutton.ng-dirty.ng-invalid .ui-button,
body p-togglebutton.ng-dirty.ng-invalid .ui-button {
  border: 1px solid $error-color !important;
}

label {
  margin-top: 0.5rem;
  margin-bottom: 0;
}


.icon-with-space{
  margin-right:2px;
}

.snackbar-container > div {
  padding: 10px !important;
  margin-bottom: 2vh !important;
}

.mat-mdc-simple-snack-bar > div {
  font-size: 16px !important;
}

.mat-mdc-snack-bar-actions > button {
  font-size: 16px !important;
}
