﻿$background-color: #D9D9D9;
$header-color: #1A93D1;
$primary-button-color: #1A93D1;
$secondary-button-color: white;
$primary-button-disabled: #98BED1;
$primary-text-color: black;
$secondary-text-color: #f5f5f5;
$light-background-color: white;
$badge-color: #363636;
$green-status-color:#54DE54;
$red-status-color: #E93641;
$blue-status-color: #50A9D6;
$border-color: #a6a6a6;
$seperator-line-color: lightgray;
$progress-spinner-color: #1A93d1;
$error-color:red;
$card-background-color: white;
$scroll-item-color: #b0b0b0;
$scroll-item-hover: #777777;
$primary-text-disabled: #a6a6a6;
$input-background-disabled: #fafafa;

$card-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.05),
0 4px 5px 1px rgba(0, 0, 0, 0.28), 0 3px 10px 2px rgba(0, 0, 0, 0);

$card-shadow-highlight: 0 3px 3px -3px rgba(0, 0, 0, 0.2),
0 5px 6px 2px rgba(0, 0, 0, 0.48), 0 3px 10px 2px rgba(0, 0, 0, 0.32);
